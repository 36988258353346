const actions = {}

const mutations = {}

const getters = {}

const state = {}

export default {
  state,
  getters,
  mutations,
  actions
}
